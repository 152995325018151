<template>
  <div class="operation_record">
    <a-table :dataSource="dataSource" :columns="columns" :row-key="record => record.rowKey" :size="'small'" :pagination="false" :loading="iconLoading">
      <template #create_time="{ record }">
        <span>{{ format_date(new Date(Number(record.Createtime + '000')), 'YYYY-MM-DD hh:mm:ss') }}</span>
      </template>
    </a-table>
    <a-pagination
      :total="total"
      :show-total="total => `总共 ${total} 条`"
      :page-size="100"
      :size="'small'"
      v-model:current="currentPage"
      @change="paginationChange"
    />
  </div>
</template>

<script lang="ts">
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'

const columns = [
  {
    title: '操作者',
    dataIndex: 'UserName',
    key: 'UserName',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'Createtime',
    key: 'Createtime',
    slots: { customRender: 'create_time' },
    align: 'center'
  },
  {
    title: 'ID',
    dataIndex: 'Id',
    key: 'Id',
    align: 'center'
  },
  {
    title: 'IP',
    dataIndex: 'Ip',
    key: 'Ip',
    align: 'center'
  },
  {
    title: 'Log',
    dataIndex: 'Log',
    key: 'Log',
    align: 'center'
  }
]

export default defineComponent({
  name: 'OperationRecord',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/adm_log?page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data.list
          total.value = res.data.total
        }
      })
    }

    onMounted(() => {
      getList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      format_date,
    }
  }
})
</script>

<style lang="scss">
.operation_record {
  .search-bar {
    height: 40px;
    margin-bottom: 15px;

    .label {
      width: 40px;
      display: inline;

      .anticon {
        margin: 0 3px;
      }
    }

    .ant-input {
      display: inline;
      width: 250px;
      margin: 0 10px;
    }

    .ant-btn {
      margin: 0 10px;
    }
  }

  .ant-pagination {
    margin: 20px 0;
    display: flex;
    justify-content: flex-end;
  }
}

.editable-cell {
  position: relative;
  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    margin-top: 4px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
}
.editable-cell:hover .editable-cell-icon {
  display: inline-block;
}
</style>
