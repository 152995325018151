
import http from '@/utils/http'
import { defineComponent, onMounted, ref } from 'vue'
import { format_date } from '../../../utils/index'

const columns = [
  {
    title: '操作者',
    dataIndex: 'UserName',
    key: 'UserName',
    align: 'center'
  },
  {
    title: '创建时间',
    dataIndex: 'Createtime',
    key: 'Createtime',
    slots: { customRender: 'create_time' },
    align: 'center'
  },
  {
    title: 'ID',
    dataIndex: 'Id',
    key: 'Id',
    align: 'center'
  },
  {
    title: 'IP',
    dataIndex: 'Ip',
    key: 'Ip',
    align: 'center'
  },
  {
    title: 'Log',
    dataIndex: 'Log',
    key: 'Log',
    align: 'center'
  }
]

export default defineComponent({
  name: 'OperationRecord',
  setup () {
    const total = ref(0)
    const currentPage = ref(1)
    const dataSource = ref([])
    const iconLoading = ref(false)

    const paginationChange = (page: any) => {
      currentPage.value = page
      getList()
    }

    const getList = () => {
      http(`/tokenapi/adm_log?page=${currentPage.value}`, {
        method: 'get',
        headers: {
          'x-token': sessionStorage.getItem('token')
        }
      }).then((res: any) => {
        if (res.code === 200) {
          dataSource.value = res.data.list
          total.value = res.data.total
        }
      })
    }

    onMounted(() => {
      getList()
    })

    return {
      columns,
      total,
      currentPage,
      paginationChange,
      dataSource,
      iconLoading,
      format_date,
    }
  }
})
